import { FC } from 'react';
import PARAGRAPH_MAPPING from '../../../config/paragraph-mapping';
import { ParagraphFromLibraryFragment } from '../../../generated/types';

interface FromLibraryProps {
  data: ParagraphFromLibraryFragment;
  isInArticleLayout?: boolean;
}

export const FromLibrary: FC<FromLibraryProps> = ({
  data,
  isInArticleLayout,
}) => {
  return (
    <>
      {data.paragraph.content?.map((data, i) => {
        if (PARAGRAPH_MAPPING[data.__typename]) {
          const Component = PARAGRAPH_MAPPING[data.__typename];

          return (
            <Component
              key={i}
              data={data}
              isInArticleLayout={isInArticleLayout}
            />
          );
        }

        return null;
      })}
    </>
  );
};

export default FromLibrary;
